import _ from 'lodash'
import { TIME } from '../constants/stylesConstants'
import { fallback, loopObject } from './commonUtils'


const flex = (
  alignItems = 'initial',
  justifyContent = 'initial',
  isInLine = false
) => `
  display: ${fallback(isInLine, 'inline-')}flex;
  justify-content: ${justifyContent};
  align-items: ${alignItems};
`

const float = () => `
  position: relative;
  z-index: 1;
`

const highZIndex = level => `z-index: ${'9'.repeat(level)};`

const innerSpacing = space => `
  &:not(:first-of-type){
    padding-top: ${space};
  }
`

const paragraphSpacing = lineHeights => {
  const string = `
  line-height: ${lineHeights};
  ${innerSpacing(lineHeights)}
`
  return string
}

const withArrow = () => `
  font-feature-settings: "ss03" 1;
`

const swatched = () => `
  transition:
    color ease-in ${TIME.COLOR_CHANGE}ms,
    fill ease-in ${TIME.COLOR_CHANGE}ms,
    background-color ease-in ${TIME.COLOR_CHANGE}ms,
    outline ease-in ${TIME.COLOR_CHANGE}ms;
`

const noScrollBar = () => `
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`

const mixins = {
  flex,
  highZIndex,
  innerSpacing,
  paragraphSpacing,
  noScrollBar,
  float,
  swatched,
  withArrow,
  chain: function () {
    const chainedObject = {}
    let accumulatedReturn = ''
    loopObject(_.omit(mixins, 'chain'), (mixinName, originalMixin) => {
      chainedObject[mixinName] = function (...args) {
        accumulatedReturn += originalMixin(...args)
        const returnFunction = () => accumulatedReturn
        return Object.assign(returnFunction, this)
      }
    })
    return chainedObject
  }
}

export default mixins