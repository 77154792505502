import styled from 'styled-components'
import { SIZES } from '../../constants/stylesConstants'
import useIsMobile from '../../hooks/useIsMobile'
import mixins from '../../utils/mixins'


const List = ({ children }) => {
  const isMobile = useIsMobile()
  const UnorderedList = isMobile ? UnorderedListMobile : UnorderedListDesktop
  return (
    <UnorderedList>
      {children}
    </UnorderedList>
  )
}


const UnorderedListBase = styled.ul`
  list-style-type: none;
  padding: 0;

  li {
    display: flex;
    &::before {
      ${mixins.withArrow}
      content: '→';
      display: block;
      margin-right: 0.325em;
    }
  }
`


const UnorderedListDesktop = styled(UnorderedListBase)`
  margin: 1.5em 0;
  padding-left: ${SIZES.LIST_PADDING.css};
`


const UnorderedListMobile = styled(UnorderedListBase)`
  margin: 1.65em 0;

  li {
    ${mixins.innerSpacing(SIZES.LIST_PADDING_MOBILE.css)}
  }
`

export default List