import _ from 'lodash'
import { useContext } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { FONT_SIZES, SIZES } from '../../../constants/stylesConstants'
import GlobalContext from '../../../context/context'
import Pages from '../../../data/pages'
import mixins from '../../../utils/mixins'
import { extract } from '../../../utils/styleUtils'
import MenuItem from '../../common/headerItem'
import WordMark from '../../common/wordmark'
import SwatchButton from '../swatchButton'

const Header = ({ onContactToggle, className }) => {
  const { swatch } = useContext(GlobalContext)

  return (
    <Container className={className} $color={swatch.TEXT}>
      <h1>
        <Link to='/'><WordMark /></Link>
      </h1>
      <Nav>
        <MenuItem path={Pages.Projects} />
        <MenuItem path={Pages.Studio} />
        <button onClick={onContactToggle}>Contact</button>
        <SwatchButton />
      </Nav>
    </Container>
  )
}

const Container = styled.header`
  ${mixins
    .chain()
    .flex('initial', 'space-between')
    .float()
    .highZIndex(2)}
  width: ${SIZES.MAIN_W.css};
  height: ${SIZES.MARGIN.css};
  position: fixed;
  opacity: 0;

  h1, nav, a, button {
    ${mixins.swatched}
    font-size: ${FONT_SIZES.REGULAR.css};
    color: ${extract('$color')};
  }

  h1 {
    position: relative;
    top: 0.045em;
  }

  h1 a svg {
    width: auto;
    height: ${FONT_SIZES.REGULAR.mult(SIZES.WORDMARK_SVG_FACTOR).css};

    path {
      ${mixins.swatched}
      fill: ${extract('$color')};
    }
  }
`


const Nav = styled.nav`
  ${mixins.flex('center', 'center')}

  justify-self: flex-end;
  position: relative;

  a, button {
    padding: 0.25em;
    margin: 0 0.75em;
  }

  button:last-child {
    margin-right: 0;
    margin-bottom: ${FONT_SIZES.REGULAR_LEADING.sub(FONT_SIZES.REGULAR).div(2).css};
  }
`

export default Header