import imageUrlBuilder from '@sanity/image-url'
import he from 'he'
import parse, { domToReact } from 'html-react-parser'
import StudioCarousel from '../components/carousels/studioCarousel'
import apiServices from './apiServices'

const parseInstagramHandle = handle => {
  if (!handle) return {}
  const atRegExp = /^@/
  const url = `https://www.instagram.com/${handle.replace(atRegExp, '')}`
  handle = handle.match(atRegExp) ? handle : `@${handle}`
  return {
    url,
    handle
  }
}

const replaceNewLine = text => `<p>${he.decode(text).replaceAll(/\n+/g, '</p><p>')}</p>`
const parseEmDash = text => text?.replaceAll(/(?<!\s)—(?!\s)/g, ' — ')
const sanitizeText = text => replaceNewLine(parseEmDash(text))

const parseText = text => {
  if (text) return parse(sanitizeText(text))
}



const sanitizePortableText = blockContents =>
  blockContents?.map(blockContent => {
    function parseText(block) {
      if ('text' in block) return block.text = parseEmDash(block.text)
      block?.children.forEach(b => parseText(b))
    }
    parseText(blockContent)
  })


const parseStudioBio = (bio, isMobile) => {
  if (!bio) return

  return parse(sanitizeText(bio), {
    replace: domNode => {
      if (!domNode.parent && !domNode.prev)
        return <p>{!isMobile && 'TK Creative '}<StudioCarousel /> is {domToReact(domNode.children)}</p>
    }
  })
}

const parseImgData = img => {
  const refs = img.asset._ref.split('-')
  return {
    id: refs[1],
    dimensions: refs[2].split('x')
  }
}

const builder = imageUrlBuilder(apiServices.client)
const getImg = source => builder.image(source)
  .auto('format')
  .quality(85)

const parsePhoneNumber = phoneNumber => phoneNumber?.replaceAll(/[\s+()-]/g, '')
const parseProjectSubtitle = info => info.split(', ')
const parseLocation = fullLocation => {
  const streetAddress = fullLocation.split('Toronto')[0].trim()
  const postalCode = fullLocation
    .match(/[a-z][0-9][a-z] ?[0-9][a-z][0-9]$/gmi)[0]?.replaceAll(/\s/gm, '')
  return {
    streetAddress,
    postalCode
  }
}

const parserServices = {
  parseInstagramHandle,
  parseText,
  parseEmDash,
  sanitizePortableText,
  parseStudioBio,
  parseImgData,
  parsePhoneNumber,
  parseProjectSubtitle,
  parseLocation,
  getImg,
}

export default parserServices