import { useContext } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { COLORS, FONT_SIZES, SIZES, SWATCHES } from '../../../constants/stylesConstants'
import GlobalContext from '../../../context/context'
import mixins from '../../../utils/mixins'
import { extract, styleIf } from '../../../utils/styleUtils'
import WordMark from '../../common/wordmark'
import ContactMobile from '../contact/contactMobile'
import MenuMobile from '../menuMobile'
import SwatchButton from '../swatchButton'


const HeaderMobile = ({
  className,
  isOpened,
  contactIsOpened,
  onContactToggle,
  onMenuToggle,
  isLanding
}) => {
  const { swatch } = useContext(GlobalContext)

  const handleClick = off => {
    if (contactIsOpened) onContactToggle(off ?? false)
    else onMenuToggle(prev => off ?? !prev)
  }

  return (
    <>
      {isOpened && <MenuMobile
        onClose={() => onMenuToggle(false)}
        onContactToggle={onContactToggle} />}
      {contactIsOpened && <ContactMobile />}
      <Container
        className={className}
        $color={
          contactIsOpened ?
            swatch.CONTACT_TEXT :
            isOpened ? swatch.MENU_TEXT :
              isLanding ? COLORS.BLACK : swatch.TEXT
        }
        $isLanding={isLanding}
        $isLandingClosed={isLanding && !isOpened && !contactIsOpened}>
        {(!isLanding || isOpened || contactIsOpened) &&
          <h1><Link to='/' onClick={() => handleClick(false)}><WordMark /></Link></h1>}
        <Nav $contactIsOpened={contactIsOpened}>
          {!isOpened && !isLanding && <SwatchButton />}
          <button onClick={() => handleClick()}>
            {contactIsOpened ? '[close]' : isOpened ? '-' : '+'}
          </button>
        </Nav>
      </Container>
    </>
  )
}

const Container = styled.header`
  ${mixins
    .chain()
    .flex('center', 'space-between')
    .float()
    .highZIndex(4)}
  width: ${styleIf('$isLandingClosed', 'fit-content', SIZES.MAIN_W_MOBILE.css)};
  height: ${FONT_SIZES.REGULAR_MOBILE.css};
  position: fixed;
  opacity: ${styleIf('$isLanding', 1, 0)};

  h1 {
    font-size: ${FONT_SIZES.REGULAR_MOBILE.css};
    position: relative;
    left: -0.4em;

    a {
      padding: 0.4em;
      display: flex;
    }
  }

  h1, nav, a, button {
    color: ${extract('$color')};
  }

  h1 a svg {
    width: auto;
    height: ${FONT_SIZES.REGULAR_MOBILE.mult(SIZES.WORDMARK_SVG_FACTOR).css};
    path {
      fill: ${extract('$color')};
    }
  }
`


const Nav = styled.nav`
  ${mixins.flex('center', 'center')}
  justify-self: flex-end;
  position: relative;

  button {
    font-size: ${styleIf('$contactIsOpened', FONT_SIZES.REGULAR_MOBILE.css, FONT_SIZES.BUTTON_MOBILE.css)};
    line-height: 1em;
    padding: 0.4em;
    position: relative;
    left: 0.4em;
    top: ${styleIf('$contactIsOpened', '0.05em')};
  }
`

export default HeaderMobile