import { useRef } from 'react'
import useMemoRef from './useMemoRef'

const useElemHeight = (dependencies = []) => {
  const ref = useRef()
  const heightRef = useMemoRef(() =>
    ref.current?.getBoundingClientRect().height, dependencies)
  return [ref, heightRef]
}

export default useElemHeight