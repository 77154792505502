import { useContext } from 'react'
import styled from 'styled-components'
import GlobalContext from '../../context/context'
import { extract, styleIf } from '../../utils/styleUtils'
import useIsMobile from '../../hooks/useIsMobile'


const SwatchButton = () => {
  const { swatch, changeSwatch } = useContext(GlobalContext)
  const isMobile = useIsMobile()
  return (
    <Button
      onClick={changeSwatch}
      $isMobile={isMobile}
      $color={swatch.BUTTON}>
      <div />
    </Button>
  )
}

const Button = styled.button`
  div {
    width: 0.75em;
    height: 0.75em;
    border-radius: 100%;
    background-color: ${extract('$color')};
    position: relative;
    top: ${styleIf('$isMobile', 0, '-0.1em')};
  }
`

export default SwatchButton