import { useContext } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { FONT_SIZES, SIZES } from '../../../constants/stylesConstants'
import GlobalContext from '../../../context/context'
import Pages from '../../../data/pages'
import parserServices from '../../../services/parserServices'
import mixins from '../../../utils/mixins'
import { extract, styleIf } from '../../../utils/styleUtils'
import Anchor from '../../common/anchor'
import { getCopyRight } from '../../../utils/commonUtils'


const FooterMobile = () => {
  const { data, swatch } = useContext(GlobalContext)
  const {
    address,
    googleMapLink,
    email,
    instagramHandle,
    phone
  } = data?.contact ?? {}

  const location = useLocation()
  const isStudio = location.pathname.match(`/${Pages.Studio}`)

  const AddressCompoonent = googleMapLink ? Anchor : 'span'
  const { url } = parserServices.parseInstagramHandle(instagramHandle)

  const [street, cityCountry] = address?.split('Toronto') ?? []
  const [city, country] = cityCountry?.split('Canada') ?? []
  return (
    <Container
      as='footer'
      $color={swatch.TEXT}
      $isStudio={isStudio}>
      <div>
        <div>
          <address>
            <AddressCompoonent to={googleMapLink}>
              <span>{street}</span>
              <span>Toronto{city}</span>
              <span>Canada{country}</span>
            </AddressCompoonent>
          </address>
        </div>
        <div>
          <span>{phone}</span>
          <span><Anchor to={`mailto:${email}`}>{email}</Anchor></span>
        </div>
        <div><Anchor to={url}>Instagram ↗</Anchor></div>
        <div>{getCopyRight()}</div>
      </div>
    </Container>
  )
}

const Container = styled.div`
  ${mixins
    .chain()
    .float()
    .withArrow()}
  font-size: ${FONT_SIZES.SMALL_MOBILE.css};
  display: flex;
  align-items: flex-end;
  min-height: ${styleIf('$isStudio', '100dvh')};
  margin-top: ${styleIf('$isStudio', 0, SIZES.FOOTER_MARGIN_TOP_MOBILE.css)};

  > div {
    padding-bottom:  ${SIZES.FOOTER_MARGIN_BOTTOM_MOBILE.css};
  }

  address, div {
    font-style: normal;
    span {
      display: block;
    }

    ${mixins.innerSpacing(FONT_SIZES.SMALL_LEADING_MOBILE.css)}
  }

  &, a {
    ${mixins.swatched}
    color: ${extract('$color')};
  }
`

export default FooterMobile