import styled from 'styled-components'
import AccordionItem from './accordionItem'

const Accordion = ({ items }) => {
  return (
    <Container>
      {items?.map(({ summary, details }, i) =>
        <AccordionItem key={i} summary={summary} details={details} />
      )}
    </Container>
  )
}

const Container = styled.ul`
  counter-reset: index;
  list-style-type: none;
  padding-left: 0;
  margin: 0;
`

export default Accordion