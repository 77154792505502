import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { FONT_SIZES } from '../constants/stylesConstants'
import GlobalContext from '../context/context'
import useIsMobile from '../hooks/useIsMobile'
import mixins from '../utils/mixins'
import { styleIf } from '../utils/styleUtils'
import FullContainer from './common/containers/fullContainer'
import Input from './common/input'

const Login = () => {
  const { handleLogin } = useContext(GlobalContext)
  const navigtate = useNavigate()
  const [value, setValue] = useState('')
  const [error, setError] = useState()
  const isMobile = useIsMobile()

  const KEY = 'password'
  const handleSubmit = e => {
    e.preventDefault()
    // eslint-disable-next-line
    if (value === process.env.REACT_APP_PRIVATE_KEY) {
      handleLogin()
      return navigtate('/')
    }
    setValue('')
    setError('Wrong Password.')
  }

  const handleChange = e => {
    e.preventDefault()
    setValue(e.target.value)
  }

  return (
    <Container>
      <div>
        <h1>Please enter the password.</h1>
        <InnerContainer $hasError={error && !value} $isMobile={isMobile}>
          <form onSubmit={handleSubmit}>
            <div>
              <Input
                name={KEY}
                value={value}
                placeholder={error}
                autoComplete='off'
                onChange={handleChange} />
              <Submit type='submit' value='→' />
            </div>
          </form>
        </InnerContainer>
      </div>
    </Container>
  )
}

const Container = styled(FullContainer)`
  ${mixins.flex('center', 'center')}
  flex-direction: column;
  > div {
    width: max-content;
  }
  h1 {
    margin-bottom: 1em;
  }
`

const InnerContainer = styled.div`
  width: 100%;
  input {
    all: unset;
  }

  div, div input {
    font-family: inherit;
    font-size: ${styleIf('$isMobile', FONT_SIZES.SMALL_MOBILE.css, FONT_SIZES.SMALL.css)};
    line-height: ${styleIf('$isMobile', FONT_SIZES.SMALL_LEADING_MOBILE.css, FONT_SIZES.SMALL_LEADING.css)};
    height: 1.5em;
  }

  &, & form {
    ${mixins.flex('center', 'center')}
  }

  &, & form {
    flex-direction: column;
  }

  form {
    ${mixins.withArrow}
    &, & > div {
      width: 100%;
    }

    > div {
      div > input {
        width: 100%;
      }

      input, input::placeholder {
        color: ${styleIf('$hasError', 'red', 'black')};
      }

      input::placeholder {
        opacity: 0.4;
        font-style: italic;
      }

      border-bottom: 1px solid ${styleIf('$hasError', 'red', 'black')};

      > div {
        width: 85%;
        flex: none;
      }
    }

    div {
      ${mixins.flex('center', 'space-between')}
    }
  }
`

const Submit = styled.input`
  margin-left: 1em;
  cursor: pointer;
`


export default Login