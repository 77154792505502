import { portableLinks } from '../../utils/reactUtils'
import List from '../common/listItem'


const studioList = {
  list: {
    bullet: ({ children }) => <List>{children}</List>
  },
  marks: portableLinks,
}

export default studioList