const breakpts = {
  xs: 430,
  s: 992,
  m: 1280,
  l: 1512,
  xl: 1728,
  xxl: 2560
}

export default breakpts
