import { createClient } from '@sanity/client'
import { QUERY } from '../constants/apiConstants'

const client = createClient({
  projectId: 'l3z81xl1',
  dataset: 'production',
  useCdn: true,
  apiVersion: '2024-09-28',
})

const data = (async () => {
  const results = await client.fetch(QUERY)
  results.landing.images = results.landing.images.filter(img => img.image)
  results.projects = results.projects
    .map(project => {
      if (project.description) return project
      const sameProject = results.projects.find(p => p.title === project.title)
      if (sameProject) project.description = sameProject.description
      return project
    })
    .filter(project => project.title && project.subtitle && project.projectImage)
  console.log(results)
  return results
})()

export default {
  client,
  data
}