import { useWindowSize } from '@uidotdev/usehooks'
import { useContext, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import { FONT_SIZES, SIZES, TIME } from '../../../constants/stylesConstants'
import GlobalContext from '../../../context/context'
import useIsMobile from '../../../hooks/useIsMobile'
import mixins from '../../../utils/mixins'
import { border, extract } from '../../../utils/styleUtils'
import parserServices from '../../../services/parserServices'


const AccordionItem = ({ summary, details }) => {
  const [isOpened, setIsOpened] = useState(false)
  const detailsRef = useRef()
  const { width } = useWindowSize()
  const { swatch } = useContext(GlobalContext)
  const isMobile = useIsMobile()

  const detailsHeight = useMemo(() =>
    isOpened ? detailsRef.current?.scrollHeight : 0, [isOpened, width])
  const Details = isMobile ? DetailsMobile : DetailsDesktop
  const Summary = isMobile ? SummaryMobile : SummaryDesktop

  return (
    <InnerContainer $color={swatch.TEXT}>
      <Summary onClick={() => setIsOpened(prev => !prev)}>
        <h4>{parserServices.parseEmDash(summary)}</h4>
        <div>{isOpened ? '-' : '+'}</div>
      </Summary>
      <Details ref={detailsRef} $maxHeight={detailsHeight}>
        <p>{parserServices.parseEmDash(details)}</p>
      </Details>
    </InnerContainer>
  )
}


const InnerContainer = styled.li`
  transition: border-color ease-in-out ${TIME.COLOR_CHANGE}ms;
  counter-increment: index;

  border-top: ${({ $color }) => border($color)};
  &:last-child {
    border-bottom: ${({ $color }) => border($color)};
  }
`

const DetailsBase = styled.div`
  transition: max-height ease-in-out ${TIME.ACCORDION}ms;
  max-height: ${extract('$maxHeight')}px;
`

const detailsPaddingTop = FONT_SIZES.SMALL.mult(0.8)
const DetailsDesktop = styled(DetailsBase)`
  overflow: hidden;
  p:last-of-type {
    padding-bottom: 0.7em;
  }
`

const DetailsMobile = styled(DetailsBase)`
  overflow: hidden;
  p {
    &:first-of-type {
      padding-top: ${FONT_SIZES.REGULAR_MOBILE.sub(SIZES.GAP_MOBILE).css};
    }

    &:last-of-type {
      padding-bottom: 0.65em;
    }
  }
`

const SummaryBase = styled.div`
  ${mixins.flex('center', 'space-between')}
  cursor: pointer;

  h4::before {
    content: counter(index) '. ';
  }
  div {
    ${mixins.flex('center', 'center')}
  }
`

const SummaryDesktop = styled(SummaryBase)`
  height: ${FONT_SIZES.SMALL_LEADING.css};
  font-size: ${FONT_SIZES.SMALL.css};
  line-height: ${FONT_SIZES.SMALL_LEADING.css};
  padding: ${detailsPaddingTop.css} 0;


  div {
    width: ${FONT_SIZES.REGULAR.mult(0.85).css};
  }
`


const SummaryMobile = styled(SummaryBase)`
  height: ${FONT_SIZES.SMALL_LEADING_MOBILE.css};
  font-size: ${FONT_SIZES.SMALL_MOBILE.css};
  line-height: ${FONT_SIZES.SMALL_LEADING_MOBILE.css};
  padding: ${SIZES.GAP_MOBILE.css} 0;

  div {
    padding-right: 0.075em;
  }
`


export default AccordionItem