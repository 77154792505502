import { useContext } from 'react'
import styled from 'styled-components'
import GlobalContext from '../../context/context'
import { extract } from '../../utils/styleUtils'
import FullContainer from './containers/fullContainer'

const Background = ({ color }) => {
  const { swatch } = useContext(GlobalContext) ?? {}
  color ??= swatch?.BACKGROUND
  return <StyledBackground $background={color} />
}


const StyledBackground = styled(FullContainer)`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
  height: 100lvh;
  background-color: ${extract('$background')};
`

export default Background