import { SEO, SEO_IMG_SIZE, SEO_TYPES, WEBSITE_SEO } from '../constants/seoConstants'
import parserServices from './parserServices'

const getWebPageName = subpage => subpage ? `${SEO.NAME} - ${subpage}` : SEO.NAME
const getDynamic = data => {
  if (!data) return {}
  const { streetAddress, postalCode } = parserServices.parseLocation(data.contact.address)
  return {
    address: {
      '@type': SEO_TYPES.POSTAL_ADDRESS,
      streetAddress,
      adddressLocality: 'Toronto',
      addressRegion: 'ON',
      addressCountry: 'CA',
      postalCode
    },
    telephone: parserServices.parsePhoneNumber(data.contact.phone),
    email: data.contact.email,
    image: data.landing.images
      .slice(0, 3)
      .map(imageObject =>
        parserServices.getImg(imageObject.image).width(SEO_IMG_SIZE).url())
  }
}

const getWebPageSchema = (data, subpage, description) => ({
  '@context': 'https://schema.org',
  '@graph': [
    {
      '@type': subpage === 'Studio' ? SEO_TYPES.ABOUT_PAGE : SEO_TYPES.WEBPAGE,
      '@id': window.location.href,
      '@url': window.location.href,
      name: getWebPageName(subpage),
      keywords: SEO.KEYWORDS,
      description,
      isPartOf: WEBSITE_SEO
    },
    {
      '@type': SEO_TYPES.LOCAL_BUSINESS,
      name: 'TK Creative',
      description: SEO.DESCRIPTION,
      ...getDynamic(data)
    }
  ]
})

const getLandingSchema = data => {
  const schema = seoServices.getWebPageSchema(
    data,
    undefined,
    'Landing page of TK Creative.'
  )

  if (data) schema['@graph'][0].primaryImageOfPage = {
    '@type': SEO_TYPES.IMAGE_OBJECT,
    name: 'TK Studio Landing Image',
    contentUrl: parserServices.getImg(data.landing.images[0].image).width(SEO_IMG_SIZE).url()
  }
  return schema
}

const seoServices = {
  getWebPageSchema,
  getLandingSchema
}

export default seoServices