import { useGSAP } from '@gsap/react'
import gsap from 'gsap'
import 'lenis/dist/lenis.css'
import { useEffect, useState } from 'react'
import usePromise from 'react-promise'
import { BrowserRouter } from 'react-router-dom'
import { createGlobalStyle } from 'styled-components'
import Desktop from './components/desktop'
import Mobile from './components/mobile'
import { LOGIN_KEY } from './constants/apiConstants'
import { SWATCHES } from './constants/stylesConstants'
import GlobalContext from './context/context'
import useIsMobile from './hooks/useIsMobile'
import apiServices from './services/apiServices'
import { mapObject, mod } from './utils/commonUtils'



gsap.registerPlugin(useGSAP)

const App = () => {
  const { value } = usePromise(apiServices.data)
  const [swatchNumber, setSwatchNumber] = useState(0)
  const swatch = mapObject(SWATCHES, (_, colors) => colors[swatchNumber])
  const changeSwatch = () => setSwatchNumber(prev => mod(prev + 1, 3))

  const [hasAnimated, setHasAnimated] = useState({
    landing: false, pages: false
  })
  const isMobile = useIsMobile()

  useEffect(() => {
    const link = document.querySelector('link[rel~=\'icon\']')
    link.href = `/assets/favicons/${swatchNumber + 1}.png`
  }, [swatchNumber])

  const [hasLogin, setHasLogin] = useState(localStorage.getItem(LOGIN_KEY) === 'true')

  const handleLogin = () => {
    setHasLogin(true)
    localStorage.setItem(LOGIN_KEY, 'true')
  }

  const handleLandingAnimated = isLanding => setHasAnimated(prev => ({ ...prev, [isLanding ? 'landing' : 'pages']: true }))
  return (
    <BrowserRouter>
      <GlobalStyle />
      <GlobalContext.Provider value={{
        data: value,
        swatch,
        changeSwatch,
        hasAnimated,
        hasLogin,
        handleLogin,
        onAnimated: handleLandingAnimated
      }}>
        {isMobile ? <Mobile /> : <Desktop />}
      </GlobalContext.Provider>
    </BrowserRouter>
  )
}

const GlobalStyle = createGlobalStyle`

  a {
    text-decoration: inherit;

    &, &:hover {
      text-decoration-thickness: 2px;
      text-underline-offset: 0.15em;
    }
  }

  button {
    display: inline;
    border: none;
    font: inherit;
    height: fit-content;
    cursor: pointer;
    background: none;
  }

  h2 {
    font-style: italic;
    padding-bottom: 1em;
  }

  h1, h2, h3, h4, h5 {
    font-weight: normal;
  }
`

export default App
