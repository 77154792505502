import _ from 'lodash'
import { createContext } from 'react'

const GlobalContext = createContext({
  data: {
    contact: {},
    landing: {},
    projects: [],
    studio: {},
  },
  swatch: {},
  changeSwatch: _.noop,
  hasAnimated: {},
  hasLogin: false,
  handleLogin: _.noop,
  onAnimated: _.noop
})

export default GlobalContext