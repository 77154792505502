import _ from 'lodash'
import { forwardRef, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import breakpts from '../../data/breakpoints'
import parserServices from '../../services/parserServices'
import { DESKTOP_BREAKPOINTS, MOBILE_BREAKPOINTS } from '../../utils/sizeUtils'
import { extract } from '../../utils/styleUtils'
import useImagesLoaded from '../../hooks/useImagesLoaded'
import useIsMobile from '../../hooks/useIsMobile'

const SanityImg = forwardRef(function SanityImg({
  img,
  width,
  height,
  style,
  getSize,
  imgComponent,
  onLoad,
  ...props
}, ref) {
  const isMobile = useIsMobile()

  const breakpointKeys = isMobile ? MOBILE_BREAKPOINTS : DESKTOP_BREAKPOINTS
  const largestVw = breakpts[_.last(breakpointKeys)]

  const { id, dimensions } = parserServices.parseImgData(img)
  const getImgSize = vw => {
    const size = getSize({
      vw,
      id,
      aspectRatio: dimensions[0] / dimensions[1]
    })

    let rounded = Math.round(size)
    rounded = rounded % 2 === 0 ? rounded : rounded + 1
    return Math.min(dimensions[0], rounded)
  }

  const src = useMemo(() => parserServices.getImg(img)
    .width(getImgSize(largestVw))
    .url(), [img, isMobile])

  const { loaded } = useImagesLoaded(onLoad ? src : undefined)
  useEffect(() => {
    if (loaded && onLoad) onLoad(loaded)
  }, [loaded])

  const srcSetData = useMemo(() => {
    const data = breakpointKeys.map((key, i) => {
      const vw = breakpts[key]
      const width = getImgSize(vw)
      const url = parserServices.getImg(img).width(width).url()
      const srcSet = `${url} ${width}w`
      const sizes = i === breakpointKeys.length - 1 ?
        `${width}px` :
        `(max-width: ${vw}px) ${width}px`

      return {
        srcSet,
        sizes,
      }
    })


    return {
      srcSet: data.map(data => data.srcSet).join(', '),
      sizes: data.map(data => data.sizes).join(', ')
    }
  }, [img, isMobile])


  return <StyledImg
    {...props}
    ref={ref}
    style={{
      ...style, width, height
    }}
    src={src}
    srcSet={srcSetData.srcSet}
    sizes={srcSetData.sizes}
    as={imgComponent}
    $aspectRatio={dimensions.join('/')} />
})

const StyledImg = styled.img`
  aspect-ratio: ${extract('$aspectRatio')};
`

export default SanityImg