import _ from 'lodash'
import { Navigate, Route, Routes } from 'react-router-dom'
import { createGlobalStyle } from 'styled-components'
import { FONT_SIZES } from '../constants/stylesConstants'
import Pages from '../data/pages'
import ProtectedRoute from './common/protectedRoute'
import Login from './login'
import LandingDesktop from './pages/landing/landingDesktop'
import Page from './pages/page/page'
import Projects from './pages/projects'
import Studio from './pages/studio'


const Desktop = () => {
  return (
    <>
      <DeviceStyle />
      <Routes>
        <Route path='/' element={<ProtectedRoute><LandingDesktop /></ProtectedRoute>} exact />
        <Route path='/' element={<ProtectedRoute><Page /></ProtectedRoute>}>
          <Route path={Pages.Projects} Component={Projects} exact />
          <Route path={Pages.Studio} Component={Studio} exact />
        </Route>
        <Route path='/login' Component={Login} />
        <Route path='*' element={<Navigate to='/' replace />} />
      </Routes>
    </>
  )
}

const DeviceStyle = createGlobalStyle`
  p, li {
    font-size: ${FONT_SIZES.REGULAR.css};
  }

  p {
    line-height: ${FONT_SIZES.REGULAR_LEADING.css};
  }

  li {
    line-height: ${FONT_SIZES.LI_LEADING.css};
  }

  h2 {
    font-size: ${FONT_SIZES.SMALL.css};
  }
`


export default Desktop
