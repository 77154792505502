import breakpts from '../data/breakpoints'
import { BreakptSizer } from './helpers/breakptSizer'

export const breakptSorted = ['xs', 's', 'm', 'l', 'xl', 'xxl']

export const Unit = {
  Vw: 'vw',
  Vh: 'vh',
  Dvw: 'dvw',
  Dvh: 'dvh',
  Px: 'px',
  '%': '%',
  Em: 'em',
  Rem: 'rem'
}

export const remify = px => `${px / getRem(1)}rem`
export const getPx = emString => parseFloat(emString.replace(/rem$/, '')) * getRem()
export const getEmifiedPx = px => getPx(remify(px))

export const getVw = (percentage = 100) => percentage / 100 * window.innerWidth
export const getVh = (percentage = 100) => percentage / 100 * window.innerHeight
export const getRem = (multiplier = 1) => parseFloat(getComputedStyle(
  document.documentElement).fontSize) * multiplier

export const getSize = breakptSizes =>
  new BreakptSizer(breakptSizes).getSize(false)

export const getRemSize = breakptSizes =>
  new BreakptSizer(breakptSizes).getSize(true)

export const getBreakpt = () => {
  const i = [...Object.values(breakpts), Infinity]
    .sort()
    .findIndex(breakpt => breakpt >= window.innerWidth) + 1
  return breakptSorted[Math.min(i, breakptSorted.length - 1)]
}

export const DESKTOP_BREAKPOINTS = ['m', 'l', 'xl', 'xxl']
export const MOBILE_BREAKPOINTS = ['xs', 's']

export const MOBILE_QUERY = `max-width: ${breakpts.s}px`