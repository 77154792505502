// eslint-disable-next-line
import ReactLenis from 'lenis/react'
import { useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { PAGE_ANIMATIONS, TIME } from '../../../constants/stylesConstants'
import useFade from '../../../hooks/useFade'
import Background from '../../common/background'
import FullContainer from '../../common/containers/fullContainer'
import Contact from '../../folio/contact/contact'
import Footer from '../../folio/footer/footer'
import Header from '../../folio/header/header'

const Page = () => {
  const location = useLocation()
  const [rendered, setRendered] = useState(false)
  const containerRef = useFade({
    data: PAGE_ANIMATIONS,
    shouldAnimate: rendered,
    duration: TIME.PAGE_DURATION,
  }, [location])
  const [contactIsOpened, setContactIsOpened] = useState(false)

  return (
    <ReactLenis root options={{ lerp: 0.5 }}>
      <Container ref={containerRef}>
        <Contact isOpened={contactIsOpened} onClose={() => setContactIsOpened(false)} />
        <Header
          onContactToggle={() => setContactIsOpened(true)}
          className={PAGE_ANIMATIONS[0].cls} />
        <Outlet context={{
          className: PAGE_ANIMATIONS[1].cls,
          onRendered: () => setRendered(true)
        }} />
        <Footer className={PAGE_ANIMATIONS[1].cls} />
        <Background />
      </Container>
    </ReactLenis>
  )
}

const Container = styled(FullContainer)`
  height: fit-content;
`

export default Page