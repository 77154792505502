export const fallback = (validatorOrString, string) => {
  if (!string) return validatorOrString || ''
  return validatorOrString ? string : ''
}

export const loopObject = (object, callback) => {
  Object.keys(object).forEach(key => {
    const value = object[key]
    callback(key, value, object)
  })
  return object
}

export const mapObject = (object, callback) => {
  const newObject = {}
  loopObject(object, (key, value, obj) =>
    newObject[key] = callback(key, value, obj))
  return newObject
}

export const quickArray = (length, callback = i => i) =>
  Array(length).fill(0).map((_, i) => callback(i))

export const map = (value, inMin, inMax, outMin = 0, outMax = 1) =>
  (value - inMin) * (outMax - outMin) / (inMax - inMin) + outMin

export const stringsAreEqual = (stringA, stringB) =>
  stringA.toLocaleUpperCase().trim() ===
  stringB.toLocaleUpperCase().trim()

export const arrayify = possibleArray =>
  Array.isArray(possibleArray) ?
    possibleArray :
    [possibleArray]

export const sortLike = (array, modelArray) =>
  array.sort((a, b) => modelArray.indexOf(a) - modelArray.indexOf(b))

export const mod = (number, upper) => (upper + number % upper) % upper

export const delta = (a, b) => Math.abs(a - b)
export const lerp = (a, b, alpha) => a + alpha * (b - a)

export const splitRetain = (string, delimiter) =>
  string
    .split(delimiter)
    .map((str, i) => i ? `${delimiter}${str}` : str)

export const getCopyRight = () => `All rights reserved ${new Date().getFullYear()}®`