import Anchor from '../components/common/anchor'

export const addEventListener = (target, type, listener, options) => {
  target.addEventListener(type, listener, options)
  return () => target.removeEventListener(type, listener, options)
}

export const portableLinks = {
  link: ({ children, value }) =>
    <Anchor to={value.href}>
      {children}
    </Anchor>
}