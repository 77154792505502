import { useGSAP } from '@gsap/react'
import gsap from 'gsap'
import _ from 'lodash'
import { useContext, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import GlobalContext from '../context/context'
import useResetScroll from './useResetScroll'

const useFade = ({
  data,
  duration = [],
  flatDelay = 0,
  shouldAnimate = true,
  callback = _.noop,
  cleanup = _.noop,
}, dependencies = []) => {
  const location = useLocation()
  const isLanding = location.pathname === '/'

  const { hasAnimated, onAnimated } = useContext(GlobalContext)
  const animated = hasAnimated[isLanding ? 'landing' : 'pages']
  const containerRef = useRef()
  useResetScroll()

  const [animationDuration, flatDuration] = duration
  useGSAP(
    () => {
      if (!shouldAnimate) return shouldAnimate
      for (let i = 0, totalDelay = 0; i < data.length; i++) {
        const animation = data[i]
        const delay = animated ? flatDelay : animation.delay + totalDelay
        const duration = animated ? flatDuration : animationDuration
        totalDelay += delay + duration

        gsap.to(`.${animation.cls}`,
          {
            duration,
            opacity: 1,
            ease: 'power1.inOut',
            delay,
            onStart: () => { if (i === data.length - 1) callback() }
          })
      }
      return cleanup
    },
    {
      scope: containerRef,
      dependencies: [...dependencies, shouldAnimate]
    }
  )

  useEffect(() => { if (shouldAnimate) onAnimated(isLanding) }, [shouldAnimate])

  return containerRef
}

export default useFade