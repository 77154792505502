import { forwardRef } from 'react'
import { Link } from 'react-router-dom'


const Anchor = forwardRef(function Anchor({ to, target, ...props }, ref) {
  const linkIsExternal = typeof to === 'string' && to.match(/^(https|www)/)
  target ||= (linkIsExternal ? '_blank' : '_self')

  return <Link
    {...props}
    ref={ref}
    to={to}
    target={target}
    rel='noreferrer' />
})


export default Anchor