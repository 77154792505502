export const LOGIN_KEY = 'tk-creative'

export const QUERY = `
{
  'contact': *[_type == 'contact']{
    address,
    googleMapLink,
    email,
    phone,
    instagramHandle,
    contactDescription
  }[0],
  'landing': *[_type == 'landing']{
    heroCopy,
    'images': landingImages[]{
      size,
      isPreset,
      image
    }
  }[0],
  'projects': *[_type == 'projects'].projects[]{
    title,
    subtitle,
    description,
    projectImage
  },
  'studio': *[_type == 'studio']{
    bio,
    'images': studioImages,
    services,
    'process': process[]{
      summary,
      details
    },
    callToAction
  }[0]
}
`