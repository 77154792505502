import { useContext } from 'react'
import styled from 'styled-components'
import { FONT_SIZES } from '../../../constants/stylesConstants'
import GlobalContext from '../../../context/context'
import parserServices from '../../../services/parserServices'
import mixins from '../../../utils/mixins'
import { extract } from '../../../utils/styleUtils'
import Anchor from '../../common/anchor'
import GridContainer from '../../common/containers/gridContainer'
import GridItem from '../../common/gridItem'
import { getCopyRight } from '../../../utils/commonUtils'


const Footer = () => {
  const { data, swatch } = useContext(GlobalContext)
  const {
    address,
    googleMapLink,
    email,
    instagramHandle,
    phone
  } = data?.contact ?? {}

  const AddressCompoonent = googleMapLink ? Anchor : 'span'
  const { url } = parserServices.parseInstagramHandle(instagramHandle)
  return (
    <Container $color={swatch.TEXT} as='footer'>
      <GridItem as='address' $end='span 4'>
        <AddressCompoonent to={googleMapLink}>
          {address}
        </AddressCompoonent>
      </GridItem>
      <GridItem $end='span 3'>
        {getCopyRight()}
      </GridItem>
      <GridItem $start='8' $end='span 2'>
        <Anchor to={`mailto:${email}`}>{email}</Anchor>
      </GridItem>
      <GridItem $end='span 2'>
        {phone}
      </GridItem>
      <GridItem>
        <Anchor to={url}>Instagram ↗</Anchor>
      </GridItem>
    </Container>
  )
}

const Container = styled(GridContainer)`
  ${mixins
    .chain()
    .float()
    .withArrow()}
  font-size: ${FONT_SIZES.SMALL.css};
  height: ${FONT_SIZES.SMALL_LEADING.css};

  address {
    font-style: normal;
  }

  &, a {
    ${mixins.swatched}
    color: ${extract('$color')};
  }

  >:last-child {
    white-space: nowrap;
  }
`

export default Footer