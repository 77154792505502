import styled from 'styled-components'
import { SIZES } from '../../../constants/stylesConstants'
import mixins from '../../../utils/mixins'


const GridContainer = styled.div`
  ${mixins.float()}
  width: 100%;
  display: grid;
  grid-template-columns: repeat(${SIZES.COL_COUNT}, 1fr);
  column-gap: ${SIZES.GAP.css};
`

export default GridContainer