import { SIZES, } from '../constants/stylesConstants'
import Size from './helpers/size'

export const extract = (key, fallbackString = '') => props =>
  fallbackString ? (props[key] ?? fallbackString) : props[key]

export const styleIf = (key, styleIfTrue, styleIfFalse = '') => props =>
  props[key] ? styleIfTrue : styleIfFalse



export const spanCol = (colCount, additionalGaps = 0, marginCount = 0) =>
  Size
    .subFromFullWidth(SIZES.MARGIN.mult(2))
    .sub(SIZES.GAP.mult(SIZES.COL_COUNT - 1))
    .div(SIZES.COL_COUNT)
    .mult(colCount)
    .add(SIZES.GAP.mult(Math.max(Math.ceil(colCount) - 1 + additionalGaps, 0)))
    .add(SIZES.MARGIN.mult(marginCount))


export const border = color => `1px solid ${color}`