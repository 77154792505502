import _ from 'lodash'
import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { styleIf } from '../../utils/styleUtils'


const MenuItem = ({ name, path, onClick }) => {
  name ??= _.capitalize(path)
  const location = useLocation()
  const underline = location.pathname === `/${path}`
  return (
    <MenuAnchor
      to={`/${path}`}
      $underline={underline}
      onClick={onClick}>
      {name}
    </MenuAnchor>
  )
}

const MenuAnchor = styled(Link)`
  text-decoration-line: ${styleIf('$underline', 'underline')};

  &:hover {
    text-decoration-line: underline;
  }
`

export default MenuItem