import styled from 'styled-components'
import { extract, styleIf } from '../../utils/styleUtils'

const GridItem = styled.div`
  text-align: ${styleIf('$alignRight', 'right')};
  grid-column-start: ${extract('$start')};
  grid-column-end: ${extract('$end')};
  height: fit-content;
`

export default GridItem