// eslint-disable-next-line
import ReactLenis from 'lenis/react'
import { useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { PAGE_ANIMATIONS, TIME } from '../../../constants/stylesConstants'
import useFade from '../../../hooks/useFade'
import Background from '../../common/background'
import FullContainer from '../../common/containers/fullContainer'
import HeaderMobile from '../../folio/header/headerMobile'
import FooterMobile from '../../folio/footer/footerMobile'


const PageMobile = ({
  menuIsOpened,
  contactIsOpened,
  onMenuToggle,
  onContactToggle
}) => {
  const location = useLocation()
  const [rendered, setRendered] = useState(false)
  const containerRef = useFade({
    data: PAGE_ANIMATIONS,
    shouldAnimate: rendered,
    duration: TIME.PAGE_DURATION,
  }, [location])

  return (
    <ReactLenis root options={{ lerp: 0.75 }}>
      <Container ref={containerRef}>
        <HeaderMobile
          className={PAGE_ANIMATIONS[0].cls}
          isOpened={menuIsOpened}
          contactIsOpened={contactIsOpened}
          onContactToggle={onContactToggle}
          onMenuToggle={onMenuToggle} />
        <Outlet context={{
          className: PAGE_ANIMATIONS[1].cls,
          onRendered: () => setRendered(true)
        }} />
        <FooterMobile className={PAGE_ANIMATIONS[1].cls} />
        <Background />
      </Container>
    </ReactLenis>
  )
}

const Container = styled(FullContainer)`
  height: fit-content;
`

export default PageMobile