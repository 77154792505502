import { useEffect, useRef } from 'react'

const useInterval = (callback, timeout, dependencies = []) => {
  const intervalRef = useRef()
  useEffect(() => {
    const interval =
      intervalRef.current =
      setInterval(callback, timeout)
    return () => clearInterval(interval)
  }, dependencies)
  return intervalRef
}

export default useInterval