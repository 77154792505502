import { useContext } from 'react'
import { Navigate } from 'react-router-dom'
import GlobalContext from '../../context/context'

const ProtectedRoute = ({ children }) => {
  const { hasLogin } = useContext(GlobalContext)
  if (!hasLogin) return <Navigate to='/login' replace />
  return children
}

export default ProtectedRoute