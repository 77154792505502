import { useWindowSize } from '@uidotdev/usehooks'
import { useContext, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { EASINGS, FONT_SIZES, SIZES, TIME } from '../../constants/stylesConstants'
import GlobalContext from '../../context/context'
import useIsMobile from '../../hooks/useIsMobile'
import parserServices from '../../services/parserServices'
import Size from '../../utils/helpers/size'
import mixins from '../../utils/mixins'
import { extract, spanCol, styleIf } from '../../utils/styleUtils'
import GridItem from './gridItem'
import SanityImg from './sanityImg'


const ProjectItem = ({
  img,
  project,
  minMargin,
  rendered,
  isScrolling,
  onImgRender,
  onCaptionRender
}) => {
  const { swatch } = useContext(GlobalContext)
  const [isHovering, setIsHovering] = useState(false)
  const { width } = useWindowSize()
  const captionRef = useRef()
  const wasScrolling = useRef(isScrolling)
  const figureRef = useRef()
  const isMobile = useIsMobile()

  useEffect(() => {
    if (!isHovering && !isScrolling) wasScrolling.current = isScrolling
  }, [isScrolling])
  const isActive = isHovering && !wasScrolling.current

  useEffect(() => onCaptionRender(captionRef.current.scrollHeight), [width])

  const handleHover = ({ clientY }, isHover) => {
    if (
      !isMobile && (
        !isActive &&
        (clientY > captionRef.current.getBoundingClientRect().top -
          SIZES.PROJECT_PARAGRAPH_PADDING.value)
      ) || (
        isActive &&
        (clientY > captionRef.current.querySelector('p').getBoundingClientRect().bottom)
      )
    ) isHover = false

    setIsHovering(isHover)
  }

  const handleDesktopHover = (e, isHover) => {
    if (!isMobile) handleHover(e, isHover)
  }

  const handleMobileHover = e => {
    if (isMobile) handleHover(e, !isHovering)
  }

  const margin = minMargin ?
    (isMobile ?
      (isHovering ?
        new Size(minMargin)
          .add(SIZES.PROJECT_PARAGRAPH_PADDING_TOP_MOBILE)
          .add(SIZES.PROJECT_SHIFT_MOBILE)
          .add(SIZES.PROJECT_MARGIN_MOBILE)
          .css : SIZES.PROJECT_MARGIN_MOBILE.css
      ) : new Size(minMargin)
        .add(SIZES.PROJECT_PARAGRAPH_PADDING)
        .add(SIZES.PROJECT_MIN_MARGIN).css) : undefined

  const Figure = isMobile ? FigureMobile : FigureDesktop
  const imgComponent = parserServices.parseImgData(img)

  const getVw = ({ vw }) => isMobile ? vw : vw / 3

  return (
    <Figure
      as='figure'
      ref={isMobile ? figureRef : undefined}
      onClick={handleMobileHover}
      onMouseMove={e => handleDesktopHover(e, true)}
      onMouseOver={e => handleDesktopHover(e, true)}
      onMouseOut={e => handleDesktopHover(e, false)}
      onMouseEnter={e => handleDesktopHover(e, true)}
      onMouseLeave={e => handleDesktopHover(e, false)}
      $isMobile={isMobile}
      $end='span 4'
      $isHovering={isActive}
      $color={swatch.TEXT}
      $tint={isActive ? swatch.TINT : undefined}
      $isWhite={swatch.BACKGROUND}
      $margin={margin}
      $opacity={rendered ? 1 : 0}>
      <div>
        <Tint
          $background={isActive ? swatch.TINT : undefined}
          $aspectRatio={imgComponent.dimensions[0] / imgComponent.dimensions[1]} />
        <SanityImg
          img={img}
          alt={`Interior Design Project for ${project.title} (${project.subtitle})`}
          getSize={getVw}
          onLoad={onImgRender} />
      </div>
      <figcaption>
        <p>{project.title}</p>
        <p>{project.subtitle}</p>
        <Details ref={captionRef} $opacity={isActive ? 1 : 0} $isMobile={isMobile}>
          {parserServices.parseText(project.description)}
        </Details>
      </figcaption>
    </Figure>
  )
}


const transitionMixins = (propertyName = 'all') => `transition: ${propertyName} ${EASINGS.IN_OUT_QUAD} ${TIME.PROJECT}ms;`
const Details = styled.div`
  ${transitionMixins('opacity')}
  position: absolute;
  overflow: hidden;
  opacity: ${extract('$opacity')};
  p:not(:first-child) {
    text-indent: 1.5em;
  }
`

const FigureBase = styled(GridItem)`
  ${mixins.swatched}
  ${({ $opacity }) => transitionMixins($opacity ? 'all' : 'opacity')}
  position: relative;
  margin: 0;
  padding-bottom: ${extract('$margin')};
  color: ${extract('$color')};
  opacity: ${extract('$opacity')};

  > div {
    ${transitionMixins()}
    background-color: ${extract('$tint')};

    img {
      display: flex;
      width: 100%;
      height: 100%;
      filter: ${styleIf('$isHovering', 'grayscale(1)')};
    }
  }

  figcaption > :first-child {
    font-style: italic;
  }
`

const FigureDesktop = styled(FigureBase)`
  top: ${styleIf('$isHovering', SIZES.PROJECT_SHIFT.css, 0)};
  figcaption {
    &, p {
      font-size: ${FONT_SIZES.SMALL.css};
      line-height: ${FONT_SIZES.SMALL_LEADING.css};
    }

    > :first-child, div {
      margin-top: ${SIZES.PROJECT_PARAGRAPH_PADDING.css};
    }
  }

 ${Details} {
    width: ${spanCol(4).css};
  }
`

const FigureMobile = styled(FigureBase)`
  top: ${styleIf('$isHovering', SIZES.PROJECT_SHIFT_MOBILE.css, 0)};
  figcaption {
    &, p {
      font-size: ${FONT_SIZES.SMALL_MOBILE.css};
      line-height: ${FONT_SIZES.SMALL_LEADING_MOBILE.css};
    }

    > :first-child {
      margin-top: ${SIZES.PROJECT_PARAGRAPH_PADDING_TOP_MOBILE.css};
    }

    > div {
      margin-top: ${SIZES.PROJECT_PARAGRAPH_PADDING_BOT_MOBILE.css};
    }
  }
`

const Tint = styled.div`
  ${mixins.highZIndex(5)}
  ${transitionMixins()}
  width: 100%;
  position: absolute;
  background-color: ${extract('$background')};
  mix-blend-mode: screen;
  aspect-ratio: ${extract('$aspectRatio')};
`

export default ProjectItem